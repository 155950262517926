import { styled, rem } from '~/design'
import { default as PL_FORM } from 'design/components/Form/Form'
import { Field as FormikField } from 'formik'

export const Form = styled(PL_FORM)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)} ${rem(24)} 0;
  ${(p) => p.theme.media.tablet} {
    padding: ${rem(48)} ${rem(40)} 0;
  }
`

export const FieldWrap = styled.div`
  flex-grow: 1;
  overflow-y: hidden;
  padding: 0 ${rem(24)};
  height: 100%;
  ${(p) => p.theme.media.mobile} {
    padding-bottom: ${rem(50)};
  }
  ${(p) => p.theme.media.tablet} {
    overflow-y: visible;
    padding: 0 ${rem(111)} 0 ${rem(40)};
    padding-bottom: ${rem(20)};
  }
`
export const Field = styled(FormikField)`
  margin-bottom: ${rem(5)};
  width: 100%;
  height: 100%;
  ${(p) => p.theme.media.desktop} {
    width: ${rem(530)};
  }
}
`
