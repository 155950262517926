import Input from 'design/components/Input/Input'
import React from 'react'
import { Tiny } from 'design/components/Typography/Typography'

/**
 * The Chakra Textarea component composes the Chakra Input component, so we
 * mirror that implementation in our own abstractions as well.
 *
 * ! IMPORTANT: We do not currently support the `resize` capability of textarea.
 *              Our implementation of form field `label`s necessitates that we
 *              set the `width` and `height` of both a textarea and its parent
 *              element. If you were to resize a textarea to be smaller, that
 *              change in width does not propagate to its parent component, and
 *              causes an error background to appear larger than the textarea.
 *              We can refactor this by displaying form field labels using the
 *              `:before` pseudoclass, and then using a visibly hidden <label>
 *              just for assistive devices.
 */
const Textarea: typeof Input = (props) => (
  <Input
    _asTextArea
    resize="none"
    rightAddonChildren={
      props.maxLength > 0 ? (
        <Tiny aria-hidden lineHeight="1">
          {props.maxLength - (props.field.value?.length || 0)}
        </Tiny>
      ) : null
    }
    {...props}
  />
)

export default Textarea
