import { useNavigate } from '@reach/router'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { SubscriptionIssue } from '~/bb-api/schemata'
import mutateSubscriptionIssue from '~/hooks/mutateSubscriptionIssue'
import PanelOtherUI from './PanelOther.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const reportOtherSchema = Yup.object().shape({
  message: Yup.string()
    .min(10, 'Provide further details.')
    .required('Provide further details.'),
})

const PanelOther: React.FC<CancelPanelProps> = ({ panel }) => {
  const navigate = useNavigate()
  const [mutate] = mutateSubscriptionIssue()

  const handleFormSubmit = (data: SubscriptionIssue) => {
    mutate(data, {
      onSuccess() {
        navigate('/are-you-sure')
      },
    })
  }

  return (
    <Formik
      initialValues={{ issueType: 'other', message: '' }}
      onSubmit={handleFormSubmit}
      validateOnBlur
      validateOnChange={false}
      validationSchema={reportOtherSchema}
    >
      {({ isSubmitting, handleSubmit, errors }) => {
        return (
          <PanelOtherUI
            errors={errors}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            panel={panel}
          />
        )
      }}
    </Formik>
  )
}

export default PanelOther
