import { rem } from 'design'
import { SmallButtonPrimary } from 'design/components/Button/Button'
import { ModalFooter } from 'design/components/Modal/Modal'
import Textarea from 'design/components/Textarea/Textarea'
import { Body, H2 } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import { getFirstFormikError } from '~/utils'
import * as Styled from './PanelOther.styles'
import * as Types from './PanelOther.types'

const PanelOtherUI: React.FC<Types.PanelOtherUIProps> = ({
  panel,
  handleSubmit,
  isSubmitting,
  errors,
}) => {
  return (
    <PanelLayout ContentWrapper={null}>
      <Styled.Form onSubmit={handleSubmit} title="Other issue feedback form">
        <Styled.Header>
          <H2 pb={rem(8)}>{panel.headline}</H2>
          <Body pb={rem(16)}>{panel.subhead}</Body>
        </Styled.Header>
        <Styled.FieldWrap>
          <Styled.Field
            component={Textarea}
            height="100%"
            label="Please provide further details"
            name="message"
          />
        </Styled.FieldWrap>
        <ModalFooter flexShrink={0}>
          <SmallButtonPrimary
            data-cy={TEST_ID.CANCEL_FLOW_STOP_SUBSCRIPTION}
            error={getFirstFormikError(errors)}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
          >
            Next
          </SmallButtonPrimary>
        </ModalFooter>
      </Styled.Form>
    </PanelLayout>
  )
}

export default PanelOtherUI
